<template>
    <div class="terminal" @keydown="handleKeyDown">
        <div v-for="(i, j) in history" :key="j" class="history">
            <span class="symbol" v-if="i.type == 'user'">
                <span style="color: greenyellow;">user@ADMIN</span>
                <span>:</span>
                <span style="color: cyan;">~</span>
                <span>$</span>
            </span>
            <pre :class="i.type == 'system' ? 'ascii' : ''">{{ i.text }}</pre>
        </div>
        <div class="input">
            <span class="symbol">
                <span style="color: greenyellow;">user@ADMIN</span>
                <span>:</span>
                <span style="color: cyan;">~</span>
                <span>$</span>
            </span>
            <textarea @blur="focusTextArea()" name="" id="input" v-model="text"></textarea>
        </div>
    </div>
    <div class="socials">
        <a href="https://x.com/CrazyAI__">Twitter</a>
    </div>
</template>

<script lang="ts">
import axios from 'axios';
import { defineComponent } from 'vue'

export default defineComponent({
    data() {
        return {
            text: '',
            history: [] as { type: 'user' | 'system', text: string }[]
        }
    },
    methods: {
        async sendText() {
            if(this.text.trim() == '') return null
            this.history.push({ type: 'user', text: this.text })
            const input = this.text
            this.text = ''
            const res = await axios.post(process.env.VUE_APP_SERVER_URL + '/ascii', { query: input })
            this.history.push({ type: 'system', text: res.data.ascii })
        },
        handleKeyDown(e: KeyboardEvent) {
            if (e.key === 'Enter') {
                e.preventDefault()
                this.sendText()
            }
        },
        focusTextArea() {
            const textarea = document.getElementById('input') as HTMLTextAreaElement
            textarea.focus()
        }
    },
    mounted() {
        this.focusTextArea()
    }
})
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');
:root {
    font-family: "Inconsolata", monospace;
    color: white;
}
body {
    margin: 0px;
    height: 100vh;
    width: 100vw;
    font-size: 1rem;
    overflow-x: hidden;
}
.socials {
    position: fixed;
    bottom: 2rem;
    left: 0rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0rem 2rem;
}
.socials a {
    color: white;
    font-size: 1.25rem;
    text-decoration: none;
}
.terminal {
    width: 100vw;
    height: fit-content;
    min-height: 100vh;
    background: black;
    box-sizing: border-box;
    padding-bottom: 10rem;
}
.input {
    display: flex;
    color: white;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    line-height: 100%;
    gap: 1rem;
}
.terminal textarea {
    width: 100%;
    font-family: "Inconsolata", monospace;
    line-height: 100%;
    border: none;
    color: rgb(255, 255, 255);
    background: black;
    font-size: 1rem;
    height: 1rem;
    padding: 0px;
}
.terminal textarea:focus { outline: none; }
.history {
    display: flex;
    color: white;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    line-height: 100%;
    gap: 1rem;
}
.ascii {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1rem;
    line-height: 100%;
    width: 100%;
}
</style>
