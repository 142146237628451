import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "symbol"
}
const _hoisted_2 = { class: "input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "terminal",
      onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleKeyDown && _ctx.handleKeyDown(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.history, (i, j) => {
        return (_openBlock(), _createElementBlock("div", {
          key: j,
          class: "history"
        }, [
          (i.type == 'user')
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _cache[3] || (_cache[3] = [
                _createElementVNode("span", { style: {"color":"greenyellow"} }, "user@ADMIN", -1),
                _createElementVNode("span", null, ":", -1),
                _createElementVNode("span", { style: {"color":"cyan"} }, "~", -1),
                _createElementVNode("span", null, "$", -1)
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("pre", {
            class: _normalizeClass(i.type == 'system' ? 'ascii' : '')
          }, _toDisplayString(i.text), 3)
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "symbol" }, [
          _createElementVNode("span", { style: {"color":"greenyellow"} }, "user@ADMIN"),
          _createElementVNode("span", null, ":"),
          _createElementVNode("span", { style: {"color":"cyan"} }, "~"),
          _createElementVNode("span", null, "$")
        ], -1)),
        _withDirectives(_createElementVNode("textarea", {
          onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.focusTextArea())),
          name: "",
          id: "input",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.text) = $event))
        }, null, 544), [
          [_vModelText, _ctx.text]
        ])
      ])
    ], 32),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "socials" }, [
      _createElementVNode("a", { href: "https://x.com/CrazyAI__" }, "Twitter")
    ], -1))
  ], 64))
}